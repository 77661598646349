export default function Terms(props:{}) {

    return (
    <div className="termsarea">
        <h1>1. AGREEMENT</h1>
        <p>This terms and conditions “Agreement” govern the use of all software provided by, sold by, or offered for sale by ANNABELL, a Danish private limited company with the company registration number (CVR) 36992581, having its registered office at Brøndbyvester Strandvej 4, 2660 Brøndby Strand (“Licensor”), from time to time, included but not limited to all ANNABELL branded software (collectively, the “Software”), including all user manuals, technical manuals, and any other materials provided by Licensor, in printed, electronic, or other forms, that describe the Software or its use or specifications (the “Documentation”) provided to the entity identified on the applicable Purchase Order, quote, invoice, or order for and its authorized users (collectively, “Licensee”) for use under and subject to this Agreement.</p>
        <p>LICENSOR PROVIDES THE SOFTWARE SOLELY ON THE TERMS AND CONDITIONS OF THIS AGREEMENT AND ON THE CONDITION THAT LICENSEE ACCEPTS AND COMPLIES WITH THEM. BY ORDERING OR USING THE SOFTWARE, LICENSEE ACCEPTS THIS AGREEMENT AND AGREES THAT LICENSEE IS LEGALLY BOUND BY ITS TERMS. IF LICENSEE DOES NOT AGREE TO THE TERMS OF THIS AGREEMENT, LICENSOR WILL NOT AND DOES NOT LICENSE THE SOFTWARE TO LICENSEE, AND LICENSEE MUST NOT DOWNLOAD AND/OR INSTALL THE SOFTWARE OR DOCUMENTATION.</p>

        <h1>2. LICENSE</h1>
        <p>2.1 Subject to Licensee’s compliance with this Agreement, Licensor hereby grants to Licensee a non-exclusive, non-transferable, non-sublicensable, time-limited for the term only, subscription-based (as provided herein) license to use the Software solely in accordance with the Documentation. Furthermore, the right to:</p>
       
        <p className="bullet">a. Deploy the Software on a platform listed as supported in the Documentation, running a validly licensed Microsoft environment for which this Software is designed.</p>
        <p className="bullet">b. Use the Software in accordance with the Documentation; subject to the limitations and prohibitions set out and referred to in this Agreement.</p>
        <p className="bullet">c. Install the Software in Test and Staging environments, for ordinary test and backup purpose</p>
       
        <p>2.2 Licensee may not sub-license and must not purport to sub-license any rights granted under this Agreement without Licensor’s prior written consent.</p>
        <p>2.3 Save to the extent expressly permitted by this Agreement or required by applicable law on a non-excludable basis, any license granted under this Clause 2 shall be subject to the following prohibitions:</p>

        <p className="bullet">a. Licensee must not sell, resell, rent, lease, loan, supply, publish, distribute or redistribute the Software; and</p>
        <p className="bullet">b. Licensee must not alter, edit or adapt the Software; and</p>
        <p className="bullet">c. Licensee must not decompile, de-obfuscate, or reverse engineer, or attempt to decompile, de-obfuscate, reverse engineer, or otherwise attempt to derive or gain access to the source code of the Software (or any part thereof); and</p>
        <p className="bullet">d. Licensee must not remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or other intellectual property or proprietary rights notices from the Software or the Documentation including any copy thereof; and</p>
        <p className="bullet">e. Licensee must not access and use the Software for purposes of competitive analysis of the Software, for the development of a competing software product or service, or for any other purpose that is to Annabell’s commercial disadvantage.</p>

        <p>2.4 Licensee shall be responsible for the security of copies of the Software supplied under this Agreement (or created from such copies) and shall use all reasonable endeavors (including all reasonable security measures) to ensure that access to such copies is restricted to persons authorized to use them under this Agreement. This includes the responsibility for no third-party gaining access to this Software.</p>
        <p>2.5 Nothing in this Agreement shall give to the Licensee or any other person or third party any right to access or use the Source Code or constitute any license of the Source Code.</p>
        <p>2.6 The foregoing license shall automatically renew for additional terms of one (1) year unless the Licensee cancels the subscription through the online portal at least sixty (60) days before the then-current expiration date.</p>
        <p>2.7 Subject to the restrictions on Confidential Information, Licensee grants to Annabell a worldwide, perpetual, irrevocable, royalty-free license to use and incorporate into the Software any suggestion, enhancement request, recommendation, or other feedback provided by Licensee or Licensee’s Users relating to the operation, use of the Software and the related Services.</p>
        <p>2.8 Trial License. Notwithstanding anything to the contrary in Sections 2. and 4 of this Agreement, if the Software is provided by Annabell or its Affiliates for trial or evaluation purposes (“Trial License”), then the applicable license grant is found hereafter. Subject to Licensee’s compliance with this Agreement, Annabell grants, in its sole discretion, a non-exclusive, personal, non-transferable, non-sublicensable, time-limited, royalty-free license, to download, execute, install, and use the Software for Licensee’s internal software evaluation purposes only and expressly excluding any production use. The license for the Trial is subject to termination for convenience by Annabell, at any time, and for any reason, and is provided with no warranties whatsoever. For the avoidance of doubt, the Trial Use Software is provided “as is” and Annabell does not warrant, and limited trial license does not include any Support Services unless expressly provided for in a written agreement signed by the parties.</p>
        <h1>3. INTELLECTUAL PROPERTY RIGHTS</h1>
        <p>3.1 This Agreement supersedes any existing or future Frame/Master/General Terms agreement between the parties, regarding ownership of the Intellectual Property, whether it is part of a Work Product or the like. The Licensee will solely be a Licensee of the Licensor’s Software.</p>
        <p>3.2 Nothing in this Agreement shall operate to assign or transfer any Intellectual Property Rights from Licensor to Licensee, or vice versa.</p>
        <p>3.3 All title and Intellectual Property Rights in and to the content which may be accessed through the use of the Software and Documentation are the property of the respective content owner and may be protected by applicable copyright or other intellectual property laws and treaties.</p>
        <p>3.4 This Agreement grants the Licensee no rights to use such content. All rights not expressly granted are reserved by Licensor.</p>
        <p>3.5 Licensee shall use commercially reasonable efforts to safeguard the Software and Documentation (including all copies thereof) from infringement, misappropriation, theft, misuse, or unauthorized access.</p>
        
        <h1>4. REPRESENTATIONS, WARRANTIES AND DISCLAIMERS</h1>
        <p>4.1 Each party represents that it has validly entered into this Agreement and has the legal power to do so.</p>

        <p>4.2 Licensee must not remove or alter any copyright notices on any and all copies of the Software.</p>

        <p>4.3 All titles, including but not limited to copyrights, in and to the Software and any copies thereof are owned by Licensor or its licensing partners.</p>

        <p>4.4 Licensor warrants that the Software on a stand-alone basis in its unaltered state and not used in combination with any other products, technologies, or services, (i) will perform in accordance with the Documentation; and (ii) not infringe any intellectual property rights held by third parties. In the event of a breach of the foregoing warranty, Licensor shall, at its discretion, (i) deliver a new copy of the Software without the defect or error, or (ii) remedy or correct the defect or error-free of charge, provided that Licensee provides reasonable information (e.g. error-logs) for Licensor to assess and remedy the breach.</p>

        <p>4.5 Licensor provides no other warranties than those set out in Clause 4.4. For the avoidance of doubt, no warranties are provided in relation to (i) fitness for a particular purpose, (ii) the accuracy or timeliness of the Software or information available from the Software; or (iii) the interoperability with Licensee or third-party software/sites/systems. Licensee understands that the Software and Documentation are provided on an “as is” basis only. Licensee bears the entire risk of using the Software and Documentation.</p>

        <p>4.6 Licensor warrants to the Licensee that:</p>

        <p className="bullet">a. The Software will be supplied free from known viruses, worms, Trojan horses, ransomware, spyware, adware, and other malicious software programs, and</p>

        <p className="bullet">b. The Software shall incorporate security features reflecting the requirements of good industry practice.</p>

        <p>4.7 If Licensor reasonably determines, or any third party alleges, that the use of the Software by the Licensee under this Agreement infringes any person’s or legal entity’s Intellectual Property Rights, Licensor may, at its own cost and expense:</p>

        <p className="bullet">a. Modify the Software in such a way that it no longer infringes the relevant Intellectual Property Rights; or</p>

        <p className="bullet">b. Procure for the Licensee the right to use the Software under this Agreement.</p>

        <p>4.8 All of the parties’ warranties and representations in respect of the subject matter of this Agreement are expressly set out in this Agreement. To the maximum extent permitted by applicable law, no other warranties or representations concerning the subject matter of this Agreement will be implied into this Agreement or any related agreement.</p>

        <p>4.9 Licensor gives no warranty or representation that the Software will be free from Software defects, errors, and bugs.</p>

        <p>4.10 Licensee acknowledges that Licensor will not provide any legal, financial, accountancy, or tax advice under this Agreement or concerning the Software; and, except to the extent expressly provided otherwise in this Agreement, Licensor does not warrant or represent that the Software or the use of the Software by the Licensee will not give rise to any legal liability on the part of the Licensee or any other person.</p>

        <p>4.11 In no event shall Licensor be liable for any damages (including, without limitation, lost profits, business interruption, or lost information) rising out of ‘Authorized Users’ use of or inability to use the Software, even if the Licensor has been advised of the possibility of such damages. In no event will Licensor be liable for loss of data or indirect, special, incidental, consequential (including lost profit), or other damages based on contract, tort, or otherwise. Licensor shall have no liability concerning the content of the Software or any part thereof, including but not limited to errors or omissions contained therein, libel, infringements of rights of publicity, privacy, trademark rights, business interruption, personal injury, loss of privacy, moral rights or the disclosure of confidential information.</p>

        <h1>5. LIMITATION OF LIABILITY</h1>
        <p>5.1  Licensor (and its parent companies, subsidiaries, affiliates, resellers, distributors and vendors, and their officers, representatives, agents, contractors, and employees) shall not be liable for any claims, damages, liabilities, losses, costs, or expenses arising out of or related to this Agreement, the Software or Documentation, or Licensee’s or end user’s use thereof under any contract, negligence, restitution, strict liability, or other legal theory, including but not limited to:</p>

        <p className="bullet">a. Errors, interruptions of use, loss or inaccuracy of data, corruption of the Software or Documentation, or the data or information provided on or via the Software or Documentation;</p>

        <p className="bullet">b. Special, incidental, indirect, or consequential damages, including but not limited to loss of business, loss of profits, business interruption, loss of business information, loss or unavailability of or damage to data, software restoration costs, or damages for personal injury, even if Licensor has been advised of the possibility of such damages;</p>

        <p className="bullet">c. Any matters beyond Licensor’s reasonable control, including acts of God, natural disasters, war, civil disturbance, governmental action, or failure of third-party services;</p>

        <p className="bullet">d. Any amounts that exceed the total fees paid by Licensee for the Software during the twelve (12) months immediately preceding the event giving rise to such liability.</p>

        <h1>6. SERVICE LEVEL AND UPDATES</h1>
        <p>6.1 The Service Level of the platform or connected service (for example, Microsoft Azure) is outside the control of the Licensor. As such, any downtime on this platform or connected service is subject to the relevant SLA of Microsoft. While Licensor will take every precaution and expend all necessary effort to ensure that the risk of a breach of the SLA is minimized, there may be circumstances outside of the Licensor’s control or the occurrence of unlikely events that may cause the threshold to be reached. Since the Software is not typically characterized as ‘Mission Critical’ the Licensor does not provide any remedy, compensation, or refunds of charges already paid.</p>

        <p>6.2 Licensor may, at its discretion, periodically make Updates and Upgrades of the Software. It is the obligation of the Licensee to ensure updating the Software to the latest version.</p>

        <p>6.3 Operating with an older version of the Software is at the Licensee’s own discretion and will be deemed unsupported by Licensor. Support for older versions of the Software can be provided, on a case-by-case basis – and based on a separate agreement. This is solely determined by Licensor.</p>

        <p>6.4 In the event of a breach of the foregoing warranty, the Licensor shall, at its discretion;</p>

        <p className="bullet">a. Deliver a new copy of the Software without the defect or error, or</p>

        <p className="bullet">b. Remedy or correct the defect or error free of charge, provided that Licensee provides reasonable information (e.g. error logs) for the Licensor to assess and remedy the breach.</p>

        <p>6.5 Reporting an error in the licensed Software, including error logs, is done through the Annabell online portal, for which Licensee can provide further information on the error. TMS provides the ability to follow the progress of the remedy process.</p>

        <p>6.6 The Licensee must provide Annabell with reliable, accurate, and complete information as required and ensure that all assumptions are accurate.</p>

        <p className="bullet">a. If a bug or error is concluded by Annabell not to originate from the Annabell Software, the Licensee accepts to cover costs (consumed time) related to the reported ticket. Cost related to consumed time is invoiced at a negotiated rate pr. hour. Examples of such incidents are</p>

        <p className="bullet2">i. Errors in a third-party application, product, or service e.g., a product update or undocumented error, or</p>

        <p className="bullet2">ii. Issues are defined as an error in existing customization or configuration, or a mistake made by a person or a faulty process.</p>

        <h1>7. CONFIDENTIALITY</h1>
        <p>Each party (the “Receiving Party”) understands that the other party (the “Disclosing Party”) has disclosed or may disclose business, technical or financial information relating to the Disclosing Party’s business (hereinafter referred to as “Proprietary Information” of the Disclosing Party). Proprietary Information of the Licensor includes non-public information regarding features, functionality, and performance of the Software and its codes. Proprietary Information of the Licensee includes non-public data provided by the Licensee to the Licensor to enable the provision of the Software. The Receiving Party agrees: (i) to take reasonable precautions to protect such Proprietary Information, and (ii) not to use (except in performance of the Services or as otherwise permitted herein) or divulge to any third person any such Proprietary Information. The Disclosing Party agrees that the foregoing shall not apply concerning any information after five (5) years following the disclosure thereof or any information that the Receiving Party can document (a) is or becomes generally available to the public, or (b) was in its possession or known by it before receipt from the Disclosing Party, or (c) was rightfully disclosed to it without restriction by a third party, or (d) was independently developed without the use of any Proprietary Information of the Disclosing Party or (e) is required to be disclosed by law.</p>

        <h1>8. TERMINATION</h1>
        <p>8.1 Unless otherwise agreed in writing, Licensee’s subscription period is for twelve (12) months.</p>

        <p>8.2 If this Agreement is terminated by Licensee under Clause 8.3, Licensee will request a refund for any prepaid license fees covering the remainder of the subscription term after the effective date of termination.</p>

        <p>8.3 Either party may terminate this Agreement immediately by giving written notice of termination to the other party and cancel any active licenses through the Microsoft administration portal if:</p>

        <p className="bullet">a. The other party commits any material breach of this Agreement, and the breach is not remediable; or</p>

        <p className="bullet">b. The other party commits a material breach of this Agreement, and the breach is remediable, but the other party fails to remedy the breach within the period of 30 days following the giving of a written notice to the other party requiring the breach to be remedied; or</p>

        <p className="bullet">c. The other party persistently breaches this Agreement (irrespective of whether such breaches collectively constitute a material breach).</p>

        <p>8.4 No matter the reason behind the termination of this Agreement the other party must destroy all copies of the Software and Documentation in its possession.</p>

        <p>8.5 In addition to the foregoing, either Licensee or Annabell may immediately terminate any executed Agreement and this specific Agreement by written notice to the other party if: (i) the other party ceases to do business or becomes insolvent; (ii) upon the institution by the other party of insolvency, receivership or bankruptcy proceedings or any other proceedings for the settlement of debt; (iii) upon the institution of such proceedings against the other party, which are not dismissed or otherwise resolved in such party’s favor within sixty (60) days thereafter; or (iv) upon the other party making a general assignment for the benefit of creditors. In any such case, no Fees paid hereunder shall be subject to repayment or credit in whole or in part in connection with any such termination, nor relieve Licensee of its obligations to make all payments due hereunder without deduction, offset, setoff, counterclaim, or reduction, recoupment, or other charges.</p>

        <p>8.6 All sections of this Agreement, which by their nature should survive termination or expiration, will survive termination or expiration, including, without limitation, accrued rights to payment, confidentiality obligations, intellectual property rights, warranty disclaimers, limitations of liability, and miscellaneous provisions.</p>

        <h1>9. GENERAL</h1>
        <p>9.1 No breach of any provision of this Agreement shall be waived except with the express written consent of the party not in breach.</p>

        <p>9.2 If any provision of this Agreement is determined by any court or other competent authority to be unlawful and/or unenforceable, the other provisions of this Agreement will continue in effect. If any unlawful and/or unenforceable provision would be lawful or enforceable if part of it were deleted, that part will be deemed to be deleted, and the rest of the provision will continue in effect (unless that would contradict the clear intention of the parties, in which case the entirety of the relevant provision will be deemed to be deleted).</p>

        <p>9.3 This Agreement may not be varied except by a written document signed by or on behalf of each of the parties.</p>

        <p>9.4 Either party may assign, delegate, or otherwise transfer all or any part of this Agreement without prior written consent from the other party to any affiliate, provided that the assigning party shall be responsible for any failure of such affiliate to perform its obligations under this Agreement. Any other assignation, delegation, or transfer is subject to the other party’s approval, which shall not be unreasonably withheld.</p>

        <p>9.5 This Agreement and the associated Purchase Order, quote, invoice, or order form, is the entire agreement between Licensor and Licensee regarding the Licensee’s use of the Software and Documentation, and supersedes all prior and contemporaneous agreements, proposals, or representations, written or oral, concerning its subject matter. In the event of any conflict or inconsistency among the following documents, the order of precedence shall be: (1) the applicable Purchase Order, quote, invoice, or order form, (2) any exhibit, schedule, or addendum to this Agreement, (3) the body of this Agreement, and (4) the Documentation.</p>

        <p>9.6 The parties are independent contractors. This Agreement does not create a partnership, franchise, joint venture, agency, fiduciary, or employment relationship between the parties.</p>

        <p>9.7 This Agreement and all matters arising out of or relating to this Agreement shall be governed by and construed exclusively under the laws of Denmark without giving effect to any choice or conflict of law provision or rule.</p>

        <p>9.8 Except for the use of a Trial License during the Subscription Period, Annabell may, no more than once every 12 months, upon seven (7) days prior written notice to Licensee, appoint Annabell personnel or an independent third-party auditor (who is obliged to maintain confidentiality) to inspect Licensee records, systems, and if necessary only, its facilities to verify that Licensee’s installation and use of Software are in conformity with the license(s) granted by Annabell. If the audit discloses a shortfall in licenses for the Software, Licensee will immediately acquire any necessary licenses, and subscriptions, and pay applicable fees.</p>

        <p>9.9 The courts located in Copenhagen, Denmark, will have exclusive jurisdiction over any dispute relating to this Agreement, and each party consents to the exclusive jurisdiction of those courts.</p>

        <p>9.10 Both parties must comply with the ‘GDPR’ (the General Data Protection Regulation (Regulation (EU) 2016/679).</p>

        <h1>10. INVOICING AND SYSTEM DATA</h1>
        <p>10.1 Licensor might collect usage data from the Software to calculate license-based consumption, operate effectively and optimize the Software. Collected usage data does not include data that can identify or be reverse engineered to identify a person with regards to, but not limited to; ID, name, or initials. The Software does not contain or collect data about ethnicity, religious or political beliefs, sexual orientation, or any health information, and as such this form of data cannot be included in usage data.</p>

        <p>10.2 Licensor collects data about how the Licensee interacts with the Software. This can include:</p>

        <p className="bullet">a. A number of resources, time registrants, users, projects, etc. as outlined in the Purchase Order, order form, quote, or invoice, as the case may be.</p>

        <p className="bullet">b. Product use data; how features are used by the Licensee and how often they are used.</p>

        <p className="bullet">c. Platform; the Licensee’s platform, operating system, and browser used to connect to the Software. This includes version information.</p>

        <p className="bullet">d. Error reports and performance data; data about the performance of the Software and any problems the Licensee might experience.</p>

        <p>10.3 Licensor uses the data collected to improve the Software and to provide product functionalities, and solution performance enhancements – general usability of the solution for end-users, including behavioral and user interface optimizations, general statistical performance optimizations, and optimized BOT, AR, AI, and Machine Learning improvements for the benefit of the industry.</p>

        <p>10.4 Licensor does not use this data for advertising purposes.</p>

        <h1>11. LICENSE FEES</h1>
        <p>11.1 Licensee shall pay to Licensor the then applicable license fees outlined in the Purchase Order, order form, quote, or invoice, as the case may be. All license fees cover a subscription period of twelve (12) months unless otherwise agreed in writing. For the avoidance of doubt, the parties agree that subscription License Fees are fully earned upon the grant of the Software license. All License Fees are, prepaid annually without any deduction, offset, setoff, counterclaim, reduction, recoupment, or other charge and are non-refundable, except as set out expressly in Clause 8, Termination.</p>

        <p>11.2 If it is revealed that Licensee has surpassed its applicable usage limits, or Permitted Usage for the Software, it shall pay additional subscription License Fees for the necessary license where the subscription License Fee increase shall be based on the then-current subscription License Fees payable by Licensee pro-rated and co-termed to the applicable invoicing schedule in the Purchase Order. The parties shall work in good faith to execute an updated Purchase Order to reflect the actual Software License usage and updated subscription License Fees.</p>

        <p>11.3 Unpaid amounts are subject to a finance charge of 1.5% per month on any outstanding balance, or the maximum permitted by law, whichever is lower, plus all expenses (including attorneys’ fees) of collection and may result in immediate termination of the license granted to Licensee hereunder.</p>

        <p>11.4 Licensee is responsible for all taxes associated with the Software, if any, other than domestic taxes based on Licensor’s net income.</p>

        <p>11.5 All Annabell Software Licenses will be invoiced upon the Licensee’s signing of the Purchase Order. If the Annabell Software License has a subscription License Fee, the subscription period will start when the product(s) are installed, or when the Software License Purchase Order is signed, whichever comes first.</p>

        <p>11.6 It is the obligation of the Licensee to update Annabell on any changes required for future invoicing of upcoming subscription periods. This is both related to requirements for new PO number(s) and increasing or decreasing the number of users, resources, time-registrants, or any other usage-based fees.</p>

        <h1>12. NOTIFICATION OF POTENTIAL CHANGES IN PRICING</h1>
        <p>12.1 License Fees for the Software are subject to change.</p>

        <p>12.2 Changes in License Fee Structure and Fee will be announced in writing by Licensor with a 180-day notice, with effect on the following license period.</p>

        <p>12.3 Upon the announcement of changes in the License Fee Structure or Fee, Licensee has the right to terminate this Agreement for the Software when the current license period ends.</p>

        <h1>13. FORCE MAJEURE</h1>
        <p>13.1 Neither party is liable for a breach or delay in performance that is caused by an event beyond its reasonable control, including a natural disaster, disease outbreak, war, riot, terrorist action, civil commotion, malicious damage, government action, industrial action, or dispute, fire, flood, storm, or failure of third-party telecommunications or other services. For the avoidance of doubt, this Section shall not affect Licensee’s or if applicable Partner’s/Contracting Entity’s payment obligations under the Agreement.</p>

        <h1>14. SPECIAL TERMS: PARTNERS EXECUTING A PURCHASE ORDER ON BEHALF OF LICENSEE</h1>
        <p>14.1 A Partner is a separate legal entity which Annabell has a valid partner contract in place with for the promotion and sublicensing of the Software and related Services or for placing and processing orders from Licensees identified in a Purchase Order, where the partner shall take on the payment obligations on behalf of Licensee.</p>

        <p>14.2 If a Partner/Contracting Entity is entering into a Purchase Order on behalf of Licensee, then references in Section 11 (License Fees), Section 8 (Termination), and obligation to provide certain notices relating to the Agreement and use of the Software may be provided by Annabell to Partner or Contracting Entity (as applicable), and Licensee may provide notices and communications to Annabell through Partner/Contracting Entity. For the avoidance of doubt, any reference to “Licensee” in the sections mentioned herein shall be deemed to also refer to Partner/Contracting Entity as applicable if they have entered into the Purchase Order on behalf of Licensee.</p>

        <p>14.3 For the avoidance of doubt, this Agreement govern Licensee’s use of the Software provided by Annabell Annabell’s’ Affiliates only. Licensee acknowledges that Annabell or its Affiliates will not be responsible for any terms, conditions, license grant, warranties, pricing commitments, or any additional obligations offered to Licensee by the Partner/Contracting Entity or its Affiliates or any third party in any contract or otherwise if Annabell is not a party to that agreement.</p>

        <p>14.4 All terms that contradict or exceed the terms of this Agreement, or an Agreement made directly with Annabell are not Annabells’ responsibility and Annabell disclaims any liability regarding such terms, even if those terms reference Annabell. Annabell shall not be liable for any acts or omissions of the Partner/Contracting Entity or its Affiliates, or for any third-party products or services provided to Licensee by such Partner/Contracting Entity, or its Affiliates, or any other third party.</p>
   </div>);
}