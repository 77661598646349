// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { Button, Dialog, Dropdown, DropdownProps, Flex, Grid, List, Image, Loader, Pill, Popup, Segment, Text, Tree, TriangleDownIcon, TriangleEndIcon, Status } from "@fluentui/react-northstar";
import { TeamsFx } from "@microsoft/teamsfx";
import { useGraph } from "@microsoft/teamsfx-react";
import { useEffect, useState } from "react";
import { GanttTableHeader } from "../../../ganttmods/GanttTableHeader";
import GanttTooltipContent from "../../../ganttmods/GanttTooltipContent";
import { ErrorSection } from "../../errorandlogs/ErrorSection";
import { Gantt, Task, ViewMode } from "../../gantt";
import { TaskListTableDefault } from "../../gantt/components/task-list/task-list-table";
import { getMaxDate, getMinDate, getNextTaskOrProject, getStartEndDateForProject, getTaskAfter, getTaskBefore, getTaskProject, log, ordinalCompareStr1BeforeStr2 } from "../../helper";
import { GanttTaskDialog, TaskAction } from "../teams/taskdetails/GanttTaskDialog";
import { addDaysToDate } from "../../helper";
import { GanttTaskDialogMobile } from "../teams/taskdetails/GanttTaskDialogMobile";

const items = [
  {
    id: 'tree-title-customization-item-1',
    title: 'one',
    items: [
      {
        id: 'tree-title-customization-item-2',
        title: 'one one',
        items: [
          {
            id: 'tree-title-customization-item-3',
            title: 'one one one',
          },
        ],
      },
    ],
  },
  {
    id: 'tree-title-customization-item-4',
    title: 'two',
    items: [
      {
        id: 'tree-title-customization-item-5',
        title: 'two one',
      },
    ],
  },
];
const items2 = [
  {
    key: 'robert',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/RobertTolbert.jpg"
      />
    ),
    header: 'Robert Tolbert',
    headerMedia: '7:26:56 AM',
    content: 'Program the sensor to the SAS alarm through the haptic SQL card!',
  },
  {
    key: 'celeste',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CelesteBurton.jpg"
      />
    ),
    header: 'Celeste Burton',
    headerMedia: '11:30:17 PM',
    content: 'Use the online FTP application to input the multi-byte application!',
  },
  {
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },{
    key: 'cecil',
    media: (
      <Image
        avatar
        src="https://fabricweb.azureedge.net/fabric-website/assets/images/avatar/CecilFolk.jpg"
      />
    ),
    header: 'Cecil Folk',
    headerMedia: '5:22:40 PM',
    content: 'The GB pixel is down, navigate the virtual interface!',
  },
]

export function PlanGanttMobile(props: { teamsfx?: TeamsFx, planid:string, planName:string | null, viewMode:ViewMode, viewModeZoomLevel:number, showCompletedTasks:boolean, planloaded:Function, hidechart:boolean }) {
  const { teamsfx } = {
    teamsfx: undefined,
    ...props,
  };
  const [loadedplansprogress, setloadedplansprogress] = useState<number>(0);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loadedPlanId, setLoadedPlanId] = useState<string | null>(props.planid);
  const [loadedPlanName, setLoadedPlanName] = useState<string>(props.planName?props.planName:"");
  const [showCompletedTasks, setShowCompletedTasks] = useState<boolean>(props.showCompletedTasks);
  const [detailsMenuVisible, setDetailsMenuVisible] = useState<boolean>(false);
  const [detailsMenuTask, setDetailsMenuTask] = useState<Task | null>(null);
  const [menuaction, setMenuaction] = useState<TaskAction>("update");
  const [listitems, setListitems] = useState<any[]>([]);
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);

  // For usage of useGraph(), please refer to: https://www.npmjs.com/package/@microsoft/teamsfx-react#usegraph.
  const { loading, error, data, reload } = useGraph(
    async (graph, teamsfx, scope) => 
    {
        let tasklistitems = [];
        setShowCompletedTasks(props.showCompletedTasks);
        setloadedplansprogress(0);
        log('0.', loading, error, data, reload, graph, teamsfx, scope )
        setLoadedPlanId(props.planid); 
        setLoadedPlanName(props.planName?props.planName:"");
        log("1. Loading Plan & Tasks", props.planid); 
        let plantasksunsorted =  await graph.api("/planner/plans/"+props.planid+"/tasks?$expand=bucketTaskBoardFormat").get();
        log("2. Raw Tasks", plantasksunsorted, "/planner/plans/"+props.planid+"/tasks?$expand=bucketTaskBoardFormat");
        if (plantasksunsorted.value.length <= 0)
        {
          setTasks([]);
          return { plan:[], update:()=>{log('3. No Tasks')}, updateDetails:()=>{log('UpdateDetails N/A');}};
        }
        
        if (!props.showCompletedTasks)  
        {
          plantasksunsorted.value  = plantasksunsorted.value.filter((tsk:any)=>{ return tsk.percentComplete != 100;});
        }
        /*let batchrequest = {requests:[]} as any; */
        for (let i=0; i<plantasksunsorted.value.length; i++)
        {  
          setloadedplansprogress(Math.round(i/plantasksunsorted.value.length * 100));
          /*batchrequest.requests.push({id:plantasksunsorted.value[i].id, method:"GET", url:"/planner/tasks/" + plantasksunsorted.value[i].id + "/bucketTaskBoardFormat"});*/
          //let bucktskboardformat = await graph.api("/planner/tasks/" + plantasksunsorted.value[i].id + "/bucketTaskBoardFormat").get();
          plantasksunsorted.value[i].bucktskboardformatOrderHint = plantasksunsorted.value[i].orderHint;
          plantasksunsorted.value[i].orderHint= plantasksunsorted.value[i].bucketTaskBoardFormat.orderHint;
        }
        
        /*let batch = await graph.api("/$batch").post(JSON.stringify(batchrequest));*/
        let plantasks = plantasksunsorted.value.sort((a:any, b:any)=>{return (ordinalCompareStr1BeforeStr2(a.orderHint,b.orderHint));}); 
        log('3. Sorted Tasks', plantasks);  
        let bucketsunsorted =  await graph.api("/planner/plans/"+props.planid+"/buckets").get();
        log("4. Raw Buckets", bucketsunsorted);
        let buckets = bucketsunsorted.value.sort((a:any, b:any)=>{return (ordinalCompareStr1BeforeStr2(b.orderHint, a.orderHint));});
        log('5. Sorted Buckets', buckets);
        let newplan:any = [];
       
        let today:Date = new Date();
        let bucketstart = plantasks.reduce(function (a:any, b:any) { return a.startDateTime < b.startDateTime ? a : b; }).startDateTime;
        let bucketend =  plantasks.reduce(function (a:any, b:any) { return a.dueDateTime > b.dueDateTime ? a : b; }).dueDateTime;
        
        for (let b=0; b<buckets.length; b++)
        {
          let bucket = buckets[b];
          
          let bucketgantttask = 
          {
            start: bucketstart?new Date(bucketstart):today,
            end: bucketend?new Date(bucketend):today,
            name: bucket.name,
            id:  bucket.id,
            progress: 100,
            type: "project",
            styles: { progressColor: '#6462C1', progressSelectedColor: '#6B69CE',backgroundColor:'#8380FF', backgroundSelectedColor:'#9593FF' },//{ progressColor: '#7B68EE', progressSelectedColor: '#7B68EE',backgroundColor:'#7B68EE', backgroundSelectedColor:'#7B68EE' }, //4BC984, 318456
            description: bucket.name,
            hideChildren: false,
            displayOrder: (b+1)*1000,
          };
          if (plantasks.filter((tsk:any)=>{return tsk.bucketId == bucket.id;}).length >= 1)
          {
            let buckettasks = plantasks.filter((tsk:any)=>{return tsk.bucketId == bucket.id;});
            bucketgantttask.start = new Date(buckettasks.reduce(function (a:any, b:any) 
            { 
              if (b.startDateTime == null) return a;
              if (a.startDateTime == null) return b;
              if (new Date(a.startDateTime).getTime() < new Date(b.startDateTime).getTime()) 
                return  a; 
              else 
                return b;
            }).startDateTime); 
            bucketgantttask.end = new Date(buckettasks.reduce(function (a:any, b:any) 
            { 
              if (b.dueDateTime == null) return a;
              if (a.dueDateTime == null) return b;
              if (new Date(a.dueDateTime).getTime() > new Date(b.dueDateTime).getTime()) 
                return  a; 
              else 
                return b;
            }).dueDateTime);
            
            bucketgantttask.start = (bucketgantttask.start.getTime() <= new Date(1970, 1, 2).getTime())?today:bucketgantttask.start;
            bucketgantttask.end = (bucketgantttask.end.getTime() <= new Date(1970, 1, 2).getTime())?today:bucketgantttask.end;
            if (bucketgantttask.end.getTime()<=bucketgantttask.start.getTime())
            {
              bucketgantttask.end = addDaysToDate(bucketgantttask.start, 1);
            }
          
            newplan.push(bucketgantttask);
            
            let tasklistbuckettasks:any[] = [];

            for (let i=0; i<plantasks.length; i++)
            {              
              let t = plantasks[i];
              if (t.bucketId == bucket.id)
              {
                //setloadedplansprogress(Math.round(newplan.length/(plantasks.length + buckets.length-1) * 100));
                
                let startDate = t.startDateTime?new Date(t.startDateTime):bucketgantttask.start;
                let endDate = t.dueDateTime?new Date(t.dueDateTime): addDaysToDate(startDate, 1);
                if (endDate.getTime()<=startDate.getTime())
                {
                    endDate = addDaysToDate(startDate, 1);
                }
                //let bucktskboardformat = await graph.api("/planner/tasks/" + t.id + "/bucketTaskBoardFormat").get();
                let gantttask:Task = {
                  start: startDate,
                  end: endDate,
                  name: t.title,
                  id:  t.id,
                  progress: t.percentComplete,
                  type: "task",
                  project:bucket.id,
                  styles: t.percentComplete == 100?
                    {progressColor: '#e1e1e1', progressSelectedColor: '#bababa',backgroundColor:'#e1e1e1', backgroundSelectedColor:'#bababa ', barLabel:'#000000'}:
                    { progressColor: '#6462C1', progressSelectedColor: '#6B69CE',backgroundColor:'#8380FF', backgroundSelectedColor:'#9593FF' },
                  description: t.title,
                  orderHint: t.orderHint,//bucktskboardformat.orderHint,
                  displayOrder: (b+1)*1000+i+1,
                  planid: props.planid,
                  bucketid: t.bucketId

                };
                newplan.push(gantttask);
                tasklistbuckettasks.push({id:t.id, title:t.title, progress:t.percentComplete, start:startDate, end:endDate, bucketid:t.bucketId, planid:props.planid});
              }
            }
            tasklistitems.push({id:bucket.id, title:bucket.name, items:tasklistbuckettasks});
          }
        }
        
        log("6. Tasks loaded", newplan);
        
        let savePlannerTask = async (taskid:string, start:Date, end:Date) =>
        {
            graph.api("/planner/tasks/"+taskid).get().then((tsk:any)=>
            {
                let plantasks =  graph.api("/planner/tasks/"+taskid)
                    .headers({'If-Match':tsk['@odata.etag']})
                    .patch('{"startDateTime":"'+start.toISOString()+'", "dueDateTime":"'+end.toISOString()+'"}')
                    .then(()=>{log("Task Saved");})
                    .catch((ex:any)=>{log("Task Save Failed", ex);});
            });
            return "Success";
        }
        let savePlannerTaskDetails = async (taskid:string, start:Date, end:Date, name:string, percentComplete:number) =>
        {
            graph.api("/planner/tasks/"+taskid).get().then((tsk:any)=>
            {
                let plantasks =  graph.api("/planner/tasks/"+taskid)
                    .headers({'If-Match':tsk['@odata.etag']})
                    .patch('{"startDateTime":"'+start.toISOString()+'", "dueDateTime":"'+end.toISOString()+'", "title":"'+name + '", "percentComplete":'+ percentComplete + '}')
                    .then(()=>{log("Task Details Saved");})
                    .catch((ex:any)=>{log("Task Details Save Failed", ex);});
            });
            return "Success";
        }
        let newPlannerTask = async (planid:string, bucketid:string, start:Date, end:Date, name:string, orderHint:string) =>
        {
            log("New Planner Task", planid, bucketid, name, start, end,  orderHint);
            let newtask =  graph.api("/planner/tasks")
                .post('{"planId": "'+planid+'","bucketId": "' + bucketid + '","title": "'+name+'","startDateTime":"'+start.toISOString()+'", "dueDateTime":"'+end.toISOString()+'"}')
                .then((v)=>
                {
                  log("Task Created, next get and update orderHint", v); 
                  let bucktskboardformat = graph.api("/planner/tasks/" + v.id + "/bucketTaskBoardFormat").get().then((btoh)=>
                  {
                    log("Old Task Order", btoh);
                    let plantasks =  graph.api("/planner/tasks/"+v.id+"/bucketTaskBoardFormat")
                    .headers({'If-Match':btoh['@odata.etag']})
                    .patch('{"orderHint":"'+orderHint+'"}')
                    .then((ov)=>{
                      log("New Task Order Updated", ov);
                      let newbucktskboardformat = graph.api("/planner/tasks/" + v.id + "/bucketTaskBoardFormat").get().then((newbtoh)=>
                      {
                        log("New Task Order", newbtoh);
                        return newbtoh;
                      });
                      return newbucktskboardformat;
                    })
                    .catch((ex:any)=>{log("New Task OrderHint Save Failed", ex);});
                    return plantasks;
                  });
                  

                  return bucktskboardformat;
                })
                .catch((ex:any)=>{log("New Task Save Failed", ex);});  
            return newtask;          
        }
        let newTaskOrder = async (taskid:string, orderHint:string) =>
        {
            log("New Order", taskid, orderHint);
            let newOrder = orderHint.replaceAll('"', '\"');
            newOrder = newOrder.replaceAll('\\', '\\\\');
            let bucktskboardformat = graph.api("/planner/tasks/" + taskid + "/bucketTaskBoardFormat").get().then((btoh)=>
            {
              log("Old Task Order and etag", btoh);
              let plantasks =  graph.api("/planner/tasks/"+taskid+"/bucketTaskBoardFormat")
              .headers({'If-Match':btoh['@odata.etag']})
              .patch('{"orderHint":"'+newOrder+'"}')
              .then((ov)=>{
                log("New Task Order Updated", ov);
                let newbucktskboardformat = graph.api("/planner/tasks/" + taskid + "/bucketTaskBoardFormat").get().then((newbtoh)=>
                {
                  log("New Task Order", newbtoh);
                  return newbtoh;
                });
                return newbucktskboardformat;
              })
              .catch((ex:any)=>{log("New Task OrderHint Save Failed", ex);});
              return plantasks;
            });
            return bucktskboardformat;
        }
      
      log("7. Graph methods initiated", newplan);
      let viewstart = getMinDate(plantasks);//plantasks.reduce(function (a:any, b:any) { return a.startDateTime < b.startDateTime ? a : b; }).startDateTime;
      let viewend =  getMaxDate(plantasks);//plantasks.reduce(function (a:any, b:any) { return a.dueDateTime > b.dueDateTime ? a : b; }).dueDateTime;
      log("8. View Calculated",viewstart, viewend);
      props.planloaded(viewstart, viewend);
      setTasks(newplan);
      setListitems(tasklistitems);
      log("9. Ready");
      return { plan:newplan, update:savePlannerTask, updateDetails:savePlannerTaskDetails, createNewTask:newPlannerTask, newTaskOrder: newTaskOrder};
    },
    
    { scope: ["User.Read", "Tasks.ReadWrite", "offline_access"], teamsfx: teamsfx }
  );
  useEffect(()=>{
    if (props.planid != loadedPlanId)
    {
      log("Re-Loading Plan & Tasks", props.planid);
      reload();
    }
  }, [props.planid]);
  useEffect(()=>
  {
    if (props.planid != loadedPlanId || props.showCompletedTasks != showCompletedTasks)
    {
      
      log("Re-Loading Plan to show/hide completed tasks", props.planid, props.showCompletedTasks);
      reload();
    }
  }, [props.showCompletedTasks]);
  useEffect(()=>{
    /*let newlistitems = [];
    for (let i=0;i<tasks.length;i++)
    {
      let task = tasks[i];
      newlistitems.push({
        id: task.id,
        title: task.name
        
      });
    }
    setListitems(newlistitems);*/
  }, [tasks]);
  const handleTaskChange = (task: Task) => {
    
    let newTasks = tasks.map(t => (t.id === task.id ? task : t));
    if (task.project) 
    {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project = newTasks[newTasks.findIndex(t => t.id === task.project)];
      if (project.start.getTime() !== start.getTime() || project.end.getTime() !== end.getTime()) 
      {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map(t => t.id === task.project ? changedProject : t);
      }
    }
    data?.update(task.id, task.start, task.end);
    setTasks(newTasks);
  };
  const handleExpanderClick = (task: Task) => {
      
    setTasks(tasks.map(t => (t.id === task.id ? task : t)));
  };
  const updateTask = (newtasks:any[], taskid:string, taskStart:Date, taskDue:Date, taskName:string, percentComplete:number, action:TaskAction) => {
    
   
    setTasks(newtasks);
    let newlistitems = [...listitems];
    for (let i=0;i<newlistitems.length;i++)
    {
      for (let j=0;j<newlistitems[i].items.length;j++)
      {
        if (newlistitems[i].items[j].id == taskid)
        {
          
          newlistitems[i].items[j].title = taskName;
          newlistitems[i].items[j].percentComplete = percentComplete;
          newlistitems[i].items[j].start = taskStart;
          newlistitems[i].items[j].end = taskDue;
        }
      }
    }
   

    setListitems(newlistitems);
    if (data?.updateDetails != undefined)
    {
      data?.updateDetails(taskid, taskStart, taskDue, taskName, percentComplete);
    } else {log("ERROR: UpdateTasks is not ready");}
    setDetailsMenuVisible(false);
    setDetailsMenuTask(null);
  }
  const insertTask = async (planid:string, bucketid:string, newtasks:any[], taskafterid:string, taskStart:Date, taskDue:Date, taskName:string, action:TaskAction, orderHint:string) => {
    
    if (data?.createNewTask != undefined)
    {
      return data?.createNewTask(planid, bucketid, taskStart, taskDue, taskName, orderHint)
        .then((v:any)=>
        {
          //update orderhint in list
          let newtasklist = newtasks.map((t)=>{return t.name == taskName?{...t, id:v.id, orderHint:v.orderHint}:t});
          log("Task-List updated with new task", newtasklist);
          setTasks(newtasklist);
          setDetailsMenuVisible(false);
          setDetailsMenuTask(null);
          setDetailsMenuVisible(false);
          setDetailsMenuTask(null);
          
          return v;
        });
    } else 
    {
      setDetailsMenuVisible(false);
      setDetailsMenuTask(null);
      setDetailsMenuVisible(false);
      setDetailsMenuTask(null);
      log("ERROR: InsertTask is not ready");
    }
  }
  const moveTaskUp = async (tsk:Task) => 
  {
    log("MoveTaskUp", tsk, tasks);
    let tskbefore = getTaskBefore(tsk, tasks);
    if (tskbefore == undefined)
    {return;}
    let tskbeforebefore = getTaskBefore(tskbefore, tasks);
    
    if (data?.newTaskOrder != undefined)
    {
      data?.newTaskOrder(tsk.id, (tskbeforebefore != undefined && tskbeforebefore.orderHint != undefined?tskbeforebefore.orderHint as string:"")+ ' ' + (tskbefore != undefined && tskbefore.orderHint != undefined?tskbefore.orderHint:"")  + "!");
    }
    let newtasklist:Task[] = [...tasks];
    for (let i=0;i<newtasklist.length;i++)
    {
      if (newtasklist[i].id == tsk.id)
      {
        if (tskbefore != undefined && tskbeforebefore != undefined && tskbefore.displayOrder != undefined && tskbeforebefore.displayOrder != undefined)
        {
          newtasklist[i].displayOrder = tskbeforebefore?.displayOrder + (tskbefore?.displayOrder-tskbeforebefore?.displayOrder)/2;
        }
        if (tskbefore != undefined && tskbeforebefore == undefined && tskbefore.displayOrder != undefined)
        {
          let projecttsk:Task = getTaskProject(tskbefore, tasks);
          if (projecttsk?.displayOrder != undefined)
          {
            newtasklist[i].displayOrder = projecttsk?.displayOrder + (tskbefore?.displayOrder-projecttsk?.displayOrder)/2;
          }
        }
      }
    }
    log("Task-List updated after move up", newtasklist);
    setTasks([...tasks]);
    
  }
  const moveTaskDown = async (tsk:Task) => 
  {
    log("MoveTaskDown", tsk, tasks);
    let tskafter = getTaskAfter(tsk, tasks);
    if (tskafter == undefined)
    {return;}
    let tskafterafter = getTaskAfter(tskafter, tasks);
    if (data?.newTaskOrder != undefined)
    {
      data?.newTaskOrder(tsk.id, (tskafter != undefined && tskafter.orderHint != undefined?tskafter.orderHint as string:"")+ ' ' + (tskafterafter != undefined && tskafterafter.orderHint != undefined?tskafterafter.orderHint:"")  + "!");
    }
    let newtasklist:Task[] = [...tasks];
    for (let i=0;i<newtasklist.length;i++)
    {
      if (newtasklist[i].id == tsk.id)
      {
        if (tskafter != undefined && tskafterafter != undefined && tskafter.displayOrder != undefined && tskafterafter.displayOrder != undefined)
        {
          newtasklist[i].displayOrder = tskafter?.displayOrder + (tskafterafter?.displayOrder-tskafter?.displayOrder)/2;
        }
        if (tskafter != undefined && tskafterafter == undefined && tskafter.displayOrder != undefined)
        {
          //let nxttaskorproj:Task = getNextTaskOrProject(tskafter, tasks);
          //if (getNextTaskOrProject != undefined && nxttaskorproj?.displayOrder != undefined)
          {
            newtasklist[i].displayOrder = tskafter.displayOrder + 1;
          }
        }
      }
    }
    log("Task-List updated with move down", newtasklist);
    setTasks([...tasks]);
    
  }
  const cancelUpdate = () => {
    
   
    setDetailsMenuVisible(false);
    setDetailsMenuTask(null);
  }
  const ganttbardoubleclick = (tsk:Task) =>
  {
    if (tsk.type == 'project')
    {
      tsk.hideChildren = !tsk.hideChildren?true:!tsk.hideChildren;
      handleExpanderClick(tsk);
    } else
    {
      detailsmenuaction(tsk, 1);
    }           
  }
  const detailsmenuaction = (tsk:Task, action:number) =>
  {
    if (action == 1)
    {
      setMenuaction("update");
      setDetailsMenuVisible(true);
      setDetailsMenuTask(tsk);
    } else if (action == 2)
    {
      setMenuaction("insertabove");
      setDetailsMenuVisible(true);
      setDetailsMenuTask(tsk);
    }
    else if (action == 3)
    {
      setMenuaction("insertbelow");
      setDetailsMenuVisible(true);
      setDetailsMenuTask(tsk);
    }
    else if (action == 4)
    {
      setMenuaction("moveup");
      moveTaskUp(tsk);
    }
    else if (action == 5)
    {
      setMenuaction("movedown");
      moveTaskDown(tsk);
    }
  }

  useEffect(()=>{if (!loading && error) {log("load error", error);}},[loading]);

  return (
    <div className="ganttcontainer" >
      {loading && 
      <>
        <Flex vAlign="center" hAlign="center" style={{height:400}}>
        <div className="loadprogressarea" style={{width:200}}>
          <div className="loadprogressbar" style={{width:(loadedplansprogress) + '%'}}></div>
        </div>
        </Flex>
      </>}
      <ErrorSection loading={loading} error={error} reload={reload} />
      {!loading && data && tasks.length > 0 &&
      (
        <div style={{height:'100%', width:'97%'}}>
          <Tree items={listitems} style={{lineHeight:'8px'}} renderItemTitle={(Component, { content, expanded, hasSubtree, ...restProps }) => 
          {
            
            if (hasSubtree)
            {
              return (
                <Component style={{fontWeight:'bold'}} expanded={expanded} hasSubtree={hasSubtree} treeSize={1} {...restProps} className="mobiletaskitem">
                  
                  <div style={{marginLeft:'5px', padding:'20px', lineHeight:'20px', backgroundColor:'rgb(250, 249, 248)', borderRadius:'4px', boxShadow:'rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem'}}>{expanded ? <TriangleDownIcon /> : <TriangleEndIcon />}<span style={{marginLeft:5}}>{content}</span></div>
                </Component>
              );
            } else
            {
              return (     
                <Component expanded={expanded} hasSubtree={hasSubtree} {...restProps} className="mobiletaskitem" >
                          
                  <div style={{marginLeft:'20px',lineHeight:'20px', padding:'20px', backgroundColor:'rgb(250, 249, 248)', borderRadius:'4px', boxShadow:'rgba(0, 0, 0, 0.1) 0px 0.2rem 0.4rem -0.075rem'}}> {/*<Status state="unknown" title="task" /> */} {content}</div>
                </Component>
                );
            }
          }}
          
          selectedItemIds={selectedItemIds}
          onSelectedItemIdsChange={
            (elem,arg)=>
            {
              
              
              if (arg?.selectedItemIds != undefined && arg?.selectedItemIds.length > 0)
              {
                
                setMenuaction("update");
                
                let id = arg?.selectedItemIds[arg?.selectedItemIds.length-1];
                let tsk = tasks.find((tsk)=>{return tsk.id == id;});
                setDetailsMenuTask(tsk?tsk:null);
                log('Update Task Menu', tsk);
                setDetailsMenuVisible(true);
                setSelectedItemIds([]);
              }
              
            }}  >

            </Tree>

         
          <GanttTaskDialogMobile tasks={tasks} open={detailsMenuVisible} task={detailsMenuTask} planName={loadedPlanName} action={menuaction} updateTask={updateTask} insertTask={insertTask} cancelUpdate={cancelUpdate}  />
        </div>
      )}
      {!loading && data && tasks.length == 0 && (<Flex style={{height:300}} hAlign="center" vAlign="center"><h3>No tasks found in this plan. Please go <a target="_blank" href={"https://tasks.office.com/Home/Planner/#/plantaskboard?planId=" + props.planid} >http://tasks.office.com</a> to and create at least one task to start planning</h3></Flex>)}
      
    </div>
  );
}
