// This file is auto generated by Teams Toolkit to provide you instructions and reference code to implement single sign on.
// This file will use TeamsFx SDK to call Graph API to get user profile.
// Refer to this link to learn more: https://www.npmjs.com/package/@microsoft/teamsfx-react#calling-the-microsoft-graph-api.

import { ArrowLeftIcon, Avatar, Button, ComponentEventHandler, Dialog, Dropdown, DropdownProps, Flex, Loader, Menu, MenuProps, TextArea } from "@fluentui/react-northstar";
import { TeamsFx } from "@microsoft/teamsfx";
import { useGraph } from "@microsoft/teamsfx-react";
import { useEffect, useState } from "react";
import { ErrorSection } from "../../errorandlogs/ErrorSection";
import { getFromLocalCache, log, randomNumberFromText, saveToLocalCache, shhlog } from "../../helper";
import { PlanCard } from "./PlanCard";
import { MyPlans } from "./planselection/MyPlans";
import { RecentPlans } from "./planselection/RecentPlans";
import { AllPlansSearch } from "./planselection/AllPlansSearch";
import { OpenMultiplePlans } from "./planselection/OpenMultiplePlans";
import { Assignment, GanttPlan, GanttTask, Person } from "../../../ganttmods/publictypes";
import { PortfolioDashboard } from "./dashboards/PortfolioDashboard";

export function PlannerGroupsSelector(props: { teamsfx?: TeamsFx,  multipleplansselected:(plans:GanttPlan[]|null, closing:boolean)=>void, authorizeUser:Function, mobile:boolean, nolicense:Function, people:Person[] | null }) {
  const { teamsfx } = {
    teamsfx: undefined,
    ...props,
  };
  const [loadedplans, setloadedplans] = useState<string>('...');
  const [me, setme] = useState<any>(undefined);
  
  const [selectedPlan, setSelectedPlan] = useState<GanttPlan | null>(null);
  const [chosenPlansMultiple, setChosenPlansMultiple] = useState<GanttPlan[] | null>(null);

  const [allgroups, setAllgroups] = useState<any[]>([]);
  const [allplans, setAllplans] = useState<GanttPlan[]>([]);
  const [allplantitles, setAllplantitles] = useState<any[]>([]);
  const [cacheplanloaded, setCacheplanloaded] = useState<boolean>(false);
  const [menuselectedindex, setMenuselectedindex] = useState<number>(0);
  const [cacheedrecentplans, setCacheedrecentplans] = useState<any[]>([]);
  const [controlmaxheight, setcontrolmaxheight] = useState<string>('100vh');
  const [userverified, setUserverified] = useState<boolean>(false);

  // For usage of useGraph(), please refer to: https://www.npmjs.com/package/@microsoft/teamsfx-react#usegraph.
  const { loading, error, data, reload } = useGraph(
    async (graph, teamsfx, scope) => {
      try
      {
        // Call graph api use `graph` instance to get user profile information
        log('loading me, if this fails a consent is needed');
        /*let loadingcompletetimeout = setTimeout(() => {
          log("setShowAuthorize");
          setShowAuthorize(true);
        }, 5000);*/
        
        const profile:any = await graph.api("/me").get();
        const org:any = await graph.api("/organization").get();
        setme(profile);
        log('me and org', profile, org);

       // let people =  await graph.api("/me/people?$top=200&$select=displayName,id").get().catch((ex:any)=>{log("People Load Failed", ex);});
        try
        {
          let verified = await verifyUserLicense(org.value[0].id, profile.mail);
          if (verified && verified.licensed ) {
            //great, continue
            setUserverified(true);
            props.nolicense(true, org.value[0].id, profile.mail, verified.admin, verified.nootheradmins);
          } else
          {
            log("NO LICENSE!");
            props.nolicense(false, org.value[0].id, profile.mail, verified.admin);
            return {};
          }
        } catch (ex:any)
        {log("Verify user error occurred", ex);}
       
        let groupplans:any[] = [];
        try
        {
          let groupurl = "/me/transitiveMemberOf?$select=id,displayName,groupTypes";
          let allgroups:any[] = [];
          while (groupurl != null)
          {
            let groups:any = await graph.api(groupurl).get();
            allgroups = allgroups.concat(groups.value);
            groupurl = groups['@odata.nextLink'];
          }

          shhlog("Groups", allgroups);
          const batchSize = 20;
          let batchRequests: any[] = [];
          for(let i=0; i<allgroups.length;i++)
          {
            let group = allgroups[i];
            if (group['@odata.type'] == "#microsoft.graph.group")
            {
              batchRequests.push({
                id: `${i}`,
                method: "GET",
                url: `/groups/${group.id}/planner/plans`
              });
            }

            if (batchRequests.length === batchSize || i === allgroups.length - 1) 
            {
              let batchBody = { requests: batchRequests };
              let batchResponse = await graph.api("/$batch").post(batchBody);
          
              batchResponse.responses.forEach((response: any) => {
                if (response.status === 200) {
                  let plans = response.body.value;
                  plans.forEach((plan: any, j: number) => {
                    groupplans.push({'@odata.etag': plan['@odata.etag'], id:plan.planid || plan.id, owner:plan.ownerId || plan.owner, title:plan.title || plan.planName, ownerName:plan.ownerName, createdDateTime:plan.createdDateTime, plan:plan, createdBy:plan.createdBy});
                  });
                  
                } else {
                  log(` -  - Error reading plan for request ${response.id}: ${response.statusText}`, batchResponse);
                }
              });
          
              batchRequests = []; // Reset batch
            }
          }
        
          log("Group Plans", groupplans);
        } catch (ex:any)
        {
          log("Error reading groups", ex);
        }
        
        let myplans:any = await graph.api("/me/planner/plans").get();
        shhlog("My Plans", myplans);
        //concatinate groupplans and myplans and don't allow duplicates
        let concatinatedplans = myplans.value.concat(groupplans.filter((gp:any)=>{return !myplans.value.some((mp:any)=>{return mp.id == gp.id})}));
        log("My and Group Plans", concatinatedplans);
        let loadplans:GanttPlan[] = [];
        let plantitles:string[] = [];
        
        let cachedusers:any = {};
        for(let i=0; i<concatinatedplans.length;i++)
        {
          setloadedplans(i + '/' + (myplans.value.length-1));
          let plan = concatinatedplans[i];
          //shhlog(" - Plan: " + i + ", " + myplans.value[i].id, myplans.value[i]);
            try
            {
              //let plan = await graph.api("/groups/" + groups.value[i].id + "/planner/plans").get();
                
                
                //shhlog(" -  - Plan details: " + plan.title, plan);
                let ownername = '';
                if (plan.ownerName)
                {
                  ownername = plan.ownerName;
                } else if (plan.createdBy && plan.createdBy.user)
                {
                    try
                    {
                      if (cachedusers[plan.createdBy.user.id])
                      {
                        //shhlog(" -  -  - Using cached user");
                        ownername = cachedusers[plan.createdBy.user.id];
                      } else if (props.people != null && props.people.some((p:Person)=>{return p.id == plan.createdBy.user.id}))
                      {
                        let foundperson = props.people.find((p:Person)=>{return p.id == plan.createdBy.user.id});
                        if (foundperson != undefined)
                        {
                          ownername = foundperson.name;
                          cachedusers[plan.createdBy.user.id] = ownername;
                        } else {log(" -  -  - Could not find person in props.people", plan);}
                      } else
                      {
                        let createdbyuser = await graph.api("/users/" + plan.createdBy.user.id + "/").get();
                        //plog('createdbyuser', createdbyuser);
                        ownername = createdbyuser.displayName;
                        cachedusers[plan.createdBy.user.id] = ownername;
                      }
                    } catch (ex:any)
                    {
                      shhlog(' -  -  - Could not read createdby: ' + ex.message, ex, plan);
                    }
                }
                let loadedplan:GanttPlan = {groupid: plan.owner,planid:plan.id, planName:plan.title, ownerName:ownername, ownerId:plan.createdBy.user.id};
                if (plan.title == "Tasks")
                {
                  try
                  {
                    let planfirsttasks:any = await graph.api("/planner/plans/"+plan.id+"/tasks?$select=title").get();
                    loadedplan.planName = "Tasks: " + planfirsttasks.value.map((obj:any) => obj.title).join(", ");
                  } catch (ex:any)
                  {
                    log(" -  - Error loading alternative plan title: " + ex.message, ex);
                  }
                }
                loadplans.push(loadedplan);
                plantitles.push(plan.title);
                
            } catch(ex:any)
            {
              log(" -  - Error reading plan: " + ex.message, ex, plan);
            }
        }
        saveToLocalCache("officegroups", allgroups);
        saveToLocalCache("plannerplantitles", plantitles);
        saveToLocalCache("plannerplans", loadplans);
        setAllplans(loadplans);
        
        setAllplantitles(plantitles);
        setAllgroups(allgroups);
        log("Plans loaded", loadplans);
        let getportfoliodashboardtaskdata = async (plan:GanttPlan):Promise<GanttTask[]> =>
          {
            
            
            let rawtasks = await graph.api("/planner/plans/"+plan.planid+"/tasks/").get();
            
            let tasks:GanttTask[] = rawtasks.value.map((t:any)=>{
              let assns:Assignment[] = [];
              
              for (var assn in t.assignments) 
              {
                if (Object.prototype.hasOwnProperty.call(t.assignments, assn)) 
                {
                  let assignment:Assignment = {name:'Unknown', id:assn};
                  assns.push(assignment);
                }
              }
              return {id:t.id, title:t.title, name:t.title, start:t.startDateTime, end:t.dueDateTime, progress:t.percentComplete, planid:plan.planid, bucketid:t.bucketId, orderHint:t.orderHint, description:t.details?.description, assignments:assns};
            });
          
            
            return tasks;
            
          }
        return { profile, groups:allgroups, plans:loadplans, plantitles:plantitles, getportfoliodashboardtaskdata:getportfoliodashboardtaskdata };
      }
      catch (graphex:any)
      {
        log("Graph Load error", graphex);
        props.authorizeUser(graphex.message);
      }
    },
    
    { scope: ["User.Read", "Tasks.ReadWrite", "offline_access"], teamsfx: teamsfx }
  );
  useEffect(( )=>{
    if (userverified)
    {
      log("Getting cached plans...");
      let plans = getFromLocalCache("plannerplans");
      let plantitles = getFromLocalCache("plannerplantitles");
      let officegroups = getFromLocalCache("officegroups");
      if (plans != null )
      {
        
        setAllplans(plans);
        setAllplantitles(plantitles);
        setAllgroups(officegroups);
        setCacheplanloaded(true);
        log("plans from cache", plans);
      } else
      {
        log("No cached plans found!");
      }
      let recentplans = getFromLocalCache('recentplans');
      if (recentplans == null || recentplans == undefined)
      {
        recentplans = [];
        setMenuselectedindex(1);
      }
      let recentdate = new Date((new Date().setDate(new Date().getDate() - 60)));
      let updatedrecentplans = recentplans.filter((p:any)=>{return (new Date(p.opened) >= recentdate)});
      if (recentplans.length == 0)
      {setMenuselectedindex(1);}
      saveToLocalCache('recentplans', updatedrecentplans);
      setCacheedrecentplans(updatedrecentplans);
    }
  }, [userverified]);
  useEffect(()=>{
    if (props.people && props.people.length > 0)
    {
      let planswithowners = allplans.map((plan:GanttPlan)=>{
        if (plan.ownerName == undefined || plan.ownerName == null || plan.ownerName == '')
        {
          let foundperson = props.people?.find((p:Person)=>{return p.id == plan.ownerId});
          if (foundperson != undefined && foundperson != null)
          {
            return {...plan, ownerName:foundperson?.name};
          }
        }
        return plan;
      });
      setAllplans(planswithowners);
    }
  }, [props.people]);
  const verifyUserLicense = async (orgid:string, email:string):Promise<any> => 
  {
    let formattedorgid = orgid;
    let usermail = email;
    const fullfillmentrequestOptions = {
      method: 'GET',
    };
    let response = await fetch('https://plannerganttapi.azurewebsites.net/VerifyUser?secret=abU{fl@<h=0]T~{/fTo`ZC*!V^*6gfdojb:Tnc|8Q>[N[eA!pjc)B,38>rRd$EU&orgid=' + formattedorgid + '&usermail=' + usermail.toLowerCase(), fullfillmentrequestOptions)
    let json = await response.json();
    log("Verify User License", json);
    let rslt = {licensed:false, admin:false, nootheradmins:false};
    if(json == undefined || json == null || json.licensed == false || json.licensed == "false" || json.licensed == "False")
    {
      rslt = {licensed:false, admin:json.admin, nootheradmins:json.nootheradmins};
    } else 
    {
      rslt = {licensed:true, admin:json.admin, nootheradmins:json.nootheradmins};
    }
    if (rslt.licensed && rslt.admin == false)
    {
      let response = await fetch('https://plannerganttapi.azurewebsites.net/VerifyUser?secret=abU{fl@<h=0]T~{/fTo`ZC*!V^*6gfdojb:Tnc|8Q>[N[eA!pjc)B,38>rRd$EU&orgid=' + formattedorgid + '&usermail=' + usermail, fullfillmentrequestOptions)
      let json = await response.json();
      if (json.admin == true)
        {rslt.admin = true;}
      log("Verify User License Upper Case", json);
    }
    return rslt;
  }
  const planSelected = (plan:GanttPlan, isnew:boolean) =>
  {
    setcontrolmaxheight('20vh');
   let plans:GanttPlan[] = [plan];
    setChosenPlansMultiple(plans);
    props.multipleplansselected(plans, false);
    let recentplans = getFromLocalCache("recentplans");
    if (recentplans == null)
    {recentplans = [];}
    if (!recentplans.some((p:any)=>{return p.id == plan.planid}))
    {
      recentplans.push({title:plan.planName, id:plan.planid, opened:new Date()});
      cacheedrecentplans.push({title:plan.planName, id:plan.planid, opened:new Date()});
    }
    saveToLocalCache("recentplans", recentplans);
    setSelectedPlan(plan);
    
  }
  const multiplePlansSelected = (plans:GanttPlan[]) =>
  {
    //setcontrolmaxheight('20vh');
    //props.planselected(plans);
    
    setChosenPlansMultiple(plans);
    props.multipleplansselected(plans, false);
   /* setPlanchosenid(planfound.id);
    setPlanchosentitle(planfound.title);
    setPlanchosenowner(planownername);*/
  }
  const plansClose = () =>
  {
    setcontrolmaxheight('100vh');
                      
    setSelectedPlan(null);
    
    setChosenPlansMultiple(null);
    log("Plan unselected", selectedPlan);
    
    props.multipleplansselected(null, true);

    setChosenPlansMultiple(null);
    props.multipleplansselected(null, true);
  }
  return (
    <div style={props.mobile?{maxHeight:controlmaxheight}:{maxHeight:controlmaxheight}}>
      
      {(!cacheplanloaded && loading) && <Flex vAlign="center" hAlign="center" style={{width:'95vw', height:'95vh'}}><Loader label={'Loading plan ' + loadedplans} /></Flex>}
      <ErrorSection loading={loading} error={error} reload={reload} />
      
      {((!loading && data) || cacheplanloaded) && 
      (
        <div>
          {chosenPlansMultiple != undefined && chosenPlansMultiple != null && 
          <>
           {chosenPlansMultiple.length == 1 && <PlanCard title={chosenPlansMultiple[0].planName} subtitle={chosenPlansMultiple[0].ownerName} plan={chosenPlansMultiple[0]} closeBtn={true} onSelect={plansClose} ></PlanCard>}
           {chosenPlansMultiple.length > 1 && <>
                <div style={{display:'inline-flex',  flexWrap:'wrap', alignItems: 'center', overflowY:'scroll', minWidth:'60vw', marginTop:20}}>
                  {chosenPlansMultiple.map((plan:GanttPlan)=>
                  {                  
                    return (
                      <Avatar key={plan.planid} name={plan.planName} square size={'large'} style={{marginRight:10}} className={"planavatarclstxt planavatarcls" + randomNumberFromText(plan.planid, 20)} />
                    )
                  })}
                  <Button primary content="" style={{display:'flex', float:'right', height:'2.60rem', width:'2.60rem'}} icon={<ArrowLeftIcon />} iconOnly={true} onClick={plansClose} />
                </div>
              </>}
          </>}
          {chosenPlansMultiple == undefined || chosenPlansMultiple == null || chosenPlansMultiple.length == 0 ?
          <>
              <Flex space="between" style={{marginTop:20}}>
                <Menu style={{marginTop:20}} defaultActiveIndex={menuselectedindex} items={
                  [
                    {
                      key: 'recent',
                      content: 'Recent',
                    },
                    {
                      key: 'my',
                      content: 'My Plans',
                    },
                    {
                      key: 'all',
                      content: 'All Plans',
                    },
                    {
                      key: 'multiple',
                      content: 'Open Multiple Plans',
                      hidden:props.mobile
                    },
                    {
                      key: 'dashboard',
                      content: 'Dashboard',
                      /*hidden:true*/
                      /*hidden:props.mobile*/
                    }
                  ]
                }  primary pointing onActiveIndexChange={(e, v)=>{setMenuselectedindex(v?.activeIndex as number)}} />
              </Flex>
              
              
              <div style={props.mobile?{display:'inline-flex',  flexWrap:'wrap', overflowY:'scroll' , maxHeight:'75vh' /* - messes up my plans on iPhone*/ }:{display:'inline-flex',  flexWrap:'wrap', overflowY:'scroll', paddingBottom:24,maxHeight: 'calc(100vh - 88px)'}} >
              
                {allplans.length==0 && <Flex style={{height:300, width:'90vw'}} hAlign="center" vAlign="center"><h3>No plans found. Please go to <a target="_blank" href="http://tasks.office.com" rel="noopener" >http://tasks.office.com</a> to create your first plan</h3></Flex>}
                {menuselectedindex==0 && <RecentPlans allplans={allplans}  planselected={planSelected} cacheedrecentplans={cacheedrecentplans} />}
                {menuselectedindex==1 && <MyPlans allplans={allplans} loggedinusername={me.displayName} planselected={planSelected} />}
                {menuselectedindex==2 && <AllPlansSearch allplans={allplans} planselected={planSelected} />}
                {menuselectedindex==3 && !props.mobile && <OpenMultiplePlans allplans={allplans} plansselected={multiplePlansSelected} />}
                {menuselectedindex==4 && !props.mobile && <PortfolioDashboard allplans={allplans} getportfoliodashboardtaskdata={data?.getportfoliodashboardtaskdata} teamsfx={teamsfx} />}
              </div>
          </>
          :<></>
          }
          
        </div>
      )}
    </div>
  );
}
